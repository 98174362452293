.t-multiselect-custom1 {
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    @apply flex;
  }
  .p-multiselect {
    @apply w-full text-gray-700 text-md min-h-[2.75rem] border border-gray-300 rounded-lg;
    &:hover {
      @apply border-primary-300 shadow-focus-light-primary;
    }

    ::-webkit-scrollbar {
      @apply w-1.5;
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-gray-300 rounded-full;
    }

    .p-multiselect-label {
      @apply px-4 py-2.5;
    }

    .p-overlay.p-component {
      @apply top-full;
    }
  }
  .p-multiselect-items-wrapper {
    @apply px-1 max-h-[25rem];
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    @apply text-md font-medium p-3 h-11 min-h-[2.75rem] text-gray-700;

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      @apply border-gray-300 bg-gray-100;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      @apply border-primary-600 bg-primary-600 text-white;
    }
    .p-checkbox .p-checkbox-box {
      @apply border border-gray-300 bg-white flex items-center justify-center rounded-[0.25rem] h-5 w-5;
    }
    .p-checkbox {
      @apply h-5 w-5 mr-3;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      @apply text-white font-semibold text-md;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      @apply bg-primary-600;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      @apply bg-primary-600 shadow-none;
    }
  }

  .p-multiselect-panel .p-multiselect-items {
    @apply px-0 py-1;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    @apply bg-white shadow-none rounded-lg text-gray-700 text-md font-medium h-11 min-h-[2.75rem];
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    @apply shadow-none;
  }
  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    @apply bg-gray-100 rounded-lg;
  }
  .p-multiselect-header {
    @apply text-gray-700 text-md font-medium py-3 px-4 bg-gray-100;
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      @apply border-gray-300 bg-gray-100;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      @apply border-primary-600 bg-primary-600 text-white;
    }
    .p-checkbox .p-checkbox-box {
      @apply h-5 w-5 border border-gray-300 bg-white flex items-center justify-center rounded-[0.25rem];
    }
    .p-checkbox {
      @apply w-5 h-5 mr-3;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      @apply text-white font-semibold text-sm;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      @apply bg-primary-600;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      @apply border-primary-600 shadow-none;
    }
    .p-multiselect-close {
      @apply hidden;
    }
  }

  .p-multiselect-panel .p-multiselect-filter-container {
    @apply flex;

    .p-multiselect-filter-icon {
      @apply right-auto left-4;
    }
    input.p-inputtext.p-multiselect-filter {
      @apply pl-12 h-10 text-md;

      &:enabled:hover,
      &:enabled:focus {
        @apply border-primary-300 shadow-focus-light-primary;
      }

      &::placeholder {
      }
    }
  }

  .p-multiselect:not(.p-disabled).p-focus {
    @apply border-primary-300 shadow-focus-light-primary;
  }
  .p-multiselect-label {
    white-space: normal;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    @apply text-gray-400;
  }

  .p-overlay {
    @apply w-full;
    min-width: 0px !important;
  }
}
