.t-dropdown-custom {
  .p-dropdown {
    @apply flex items-center h-11 min-h-[2.75rem] w-full text-gray-700 text-md rounded-lg;

    .p-dropdown-trigger {
      @apply py-2.5 pr-4 pl-0;
      width: unset;
    }
    .p-dropdown-label.p-inputtext {
      @apply text-gray-700 text-md px-4 font-Inter;
    }

    .p-dropdown-label.p-placeholder {
      @apply text-gray-400 text-md;
    }

    .p-dropdown-item {
      @apply text-md text-gray-700;
    }

    &:not(.p-disabled).p-focus,
    &:not(.p-disabled):hover {
      @apply outline-none border border-primary-300 shadow-focus-light-primary;
    }

    ::-webkit-scrollbar {
      @apply w-1.5;
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-gray-300 rounded-full;
    }

    .p-dropdown-panel {
      .p-dropdown-items {
        @apply p-1;

        .p-dropdown-item {
          @apply text-gray-700 text-md font-medium p-3;

          &:hover,
          &.p-highlight {
            @apply bg-gray-100 rounded-lg;
          }
        }
      }
    }
  }

  &.ng-invalid.ng-touched .p-dropdown.p-component,
  &.ng-invalid.ng-dirty .p-dropdown.p-component {
    @apply border-error-600;
  }
}
