.t-checkbox-custom {
  width: 20px !important;
  height: 20px !important;
  border-radius: 6px !important;

  .p-checkbox {
    width: 20px !important;
    height: 20px !important;
    border-radius: 6px !important;
  }

  .p-checkbox-box {
    width: 20px !important;
    height: 20px !important;
    background-color: #fff !important;
    border-radius: 6px;
    border: 1px solid #d0d5dd !important;
    padding: 3px !important;
    justify-content: center;
    align-items: center !important;
    text-align: center;
  }

  .p-checkbox-icon {
    width: 14px !important;
    height: 14px !important;
    color: #1570ef !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .p-checkbox-box:hover {
    background-color: #fff !important;
    border-color: #1570ef !important;
  }

  .p-checkbox-box.p-highlight {
    border-color: #1570ef !important;
    background: #eff8ff !important;
  }

  .p-checkbox-box .p-checkbox-icon .pi .pi-check {
    width: 14px !important;
    height: 14px !important;
    color: #1570ef !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }

  .p-checkbox-box.p-focus {
    box-shadow: none;
  }
}
