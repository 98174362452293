.language-setting {
  @apply w-[480px];
  .cancel-language-btn .p-button {
    @apply text-gray-700 items-center text-base bg-white border-gray-300;
  }
  .cancel-language-btn .p-button:enabled:hover {
    @apply text-gray-700 items-center text-base bg-white border-gray-300;
  }
  .confirm-language-btn .p-button {
    @apply text-white items-center text-base bg-primary-600;
  }
  .confirm-language-btn .p-button:enabled:hover {
  }
}
.sign-out-dialog {
  .p-dialog-content {
    padding: 0px;
  }
  .cancel-sign-out-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
  }
  .confirm-sign-out-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
  }
}
.create-account {
  .p-dialog-content {
    padding: 0px;
  }
  .create-acc-text {
    @apply text-sm text-gray-500;
  }
  .cancel-create-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
  }
  .confirm-create-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
  }
}
.custom-noti-mobile > div:first-of-type {
  position: fixed;
  z-index: 9999;
}
.custom-mega-menu-mobile > div:first-of-type {
  position: fixed;
  z-index: 9999;
}
@media (max-width: 768px) {
  .menu-header i {
    @apply text-gray-500;
  }
}
#download-app-popup {
  z-index: 9999 !important;
}
