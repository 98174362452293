.t-input-custom {
  .p-inputtext {
    @apply py-2.5 pl-3.5 pr-10 w-full bg-white border border-solid border-gray-300 rounded-lg box-border text-md;
    &:enabled:focus {
      @apply border-primary-300 bg-white shadow-focus-light-primary;
    }
    &:hover {
      @apply border-primary-300 bg-white shadow-focus-light-primary;
    }
  }
}
