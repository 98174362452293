.p-contextmenu {
  @apply shadow-lg p-0;
  border: 1px solid #eaecf0 !important;
  .p-menuitem-link {
    @apply p-3 rounded-lg m-1;
    &:focus {
      @apply shadow-none;
    }
    .p-menuitem-text {
      @apply text-gray-700 text-md font-medium;
    }
  }
  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    @apply bg-gray-100;
  }
  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    @apply text-primary-600;
  }
}
