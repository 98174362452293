.t-table-custom {
  .p-datatable .p-datatable-header {
    @apply bg-white border-t-0;
  }

  .p-datatable .p-datatable-thead > tr > th {
    @apply bg-gray-100 text-md font-medium text-gray-700 py-0;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    @apply h-[3.75rem] py-0;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    @apply bg-white;
  }

  .p-datatable .p-sortable-column {
    &.p-highlight:hover {
      @apply bg-primary-100 text-primary-700;

      i::before {
        @apply text-primary-700;
      }
    }

    &:focus {
      @apply bg-primary-100 text-primary-700 shadow-none;
    }
  }
}
