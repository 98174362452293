.chip {
  @apply w-max flex items-center rounded-2xl px-2.5 py-0.5 font-medium;
}
.t-chip-1 {
  @apply text-rose-700 bg-rose-50;
}
.t-chip-2 {
  @apply text-success-700 bg-success-50;
}
.t-chip-3 {
  @apply text-indigo-700 bg-indigo-50;
}
.t-chip-4 {
  @apply text-blue-light-700 bg-blue-light-50;
}
.t-chip-5 {
  @apply text-purple-700 bg-purple-50;
}
.t-chip-6 {
  @apply text-orange-700 bg-orange-50;
}
.t-chip-7 {
  @apply text-error-700 bg-error-50;
}
.t-chip-8 {
  @apply text-warning-700 bg-warning-50;
}
